
header {
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}


ul {
   list-style: none;
   display: flex;
   justify-content: space-between;
}

li {
    padding:0 1.2vw;
    font-size: 1.4rem;
    font-weight: 600;
    color: #4E8FCB;
}



.logo-container{
    width: 20%;
    max-width: 4rem;
    min-width: 4rem;
    margin-right: 2rem;
}

.logo-container img{
    width: 80%;
}



