.policy {
    padding: 4rem 10rem;
    color: #353333;
    display: flex;
    flex-direction: column;
    align-items: center;
    

}

.policy h2   {
font-size: 2rem;
padding: 1rem 0;
text-align: left;
}

.policy p   {
    width: 60vw;
    min-width: 30rem;
    font-size: 1.4rem;
    padding: 1rem 0;
    line-height: 3rem;

}

