.kids-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 4rem;
}

.kids-container h1{
    font-family: 'Kavoon', cursive;
}

.kids-container p {
    line-height: 3rem;
}

.hero-section{
    text-align: center;
    padding: 10rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-title{
    min-width: 30rem;
    padding:0 4rem;
    color: #49B4BA;
    font-size: 5rem;
}



.castle-container{
    width: 80vw;
    max-width: 80rem;
    min-width: 40;
}

.castle-container img {
    width: 100%;
}


.about-session{
    width: 100%;
    background-color:#FFECDB;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10rem 0;

}


.about-session-text{
    width: 55vw;
    min-width: 30rem;
    color: #0C8E96;
    padding: 2rem 0;
}

.about-session-text h1 {
    font-size: 5.6rem;
    padding: 2rem 0;
}


.car1-container{
    width: 55vw;
    min-width: 30rem;
    text-align: start;
    display: flex;
    
}

.car2-container{
    width: 55vw;
    padding-top: 10rem ;
}



.educational-sect{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    text-align:start;
    padding: 18rem 0;
}


.educational-sect-text{
    width: 40%;
    min-width: 30rem;
    color: #1A8C78;
}

.educational-sect-text h1 {
    font-size: 3rem;
    padding:3rem 0 ;
}


.flower-container{
    padding-top: 3rem;
}



.schedule{
    width: 100%;
    background-color: #EEF9FF;
    padding: 5rem 0;
}

.schedule img{
    width: 80%;
}
