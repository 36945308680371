
.home {
    min-height: calc(100vh - 12rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 8rem;
}

.home-img {
    width: 80%;
    max-width: 42rem;
}


.home img {
    width: 100%;
    padding: 6rem 0;
}

.home h1 {
    font-size: 3rem;
    color: #5B5B5B;
    padding: 0 4rem;
}

