@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kavoon&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


html {
  font-size: 62.5%;
}




.App {

  max-width: 144rem;
  min-width: 32rem;
  margin: auto;
  background-color: #FFFBEE;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

button {
margin: 2rem 0 ;
padding: 1.6rem 4rem ;
background-color: #E15710;
border: none;
border-radius: 0.4rem;
color: rgb(255, 255, 255);
font-size: 1.8rem;
cursor: pointer;
}


a {
  text-decoration: none;
  color: #4E8FCB;
  padding: 0 1rem;
  font-weight: 600;
}


@media screen and ( max-width: 1080px){
  
  
  .private-art-lessons{
    flex-direction: column-reverse;
  }



  .adult-container{
    margin: 2rem 0;

   flex-direction: column-reverse;
   
  }


  .adults-hero{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }


  .educational-sect{
    flex-direction: column;
  }


  .educational-sect-text{
    text-align: center;

  }







}