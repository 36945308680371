.adult-container {
    min-height: calc(100vh - 12rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10rem;

}

.private-art-lessons{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 0;
}


.adults-hero{
    width: 60%;
    max-width: 60rem;
    min-width: 30rem;
}

.adult-container h1{
 font-size: 4.6vh;
 max-width: 46rem;
 
 color: #353333;
}


.adult-container a {
    padding: 0;
}

.adult-container p{
    font-size: 1.6rem;
    max-width: 46rem;
    color: #353333;
    padding: 2rem 0;
    line-height: 3rem;
}


.still-life-container{
width: 32%;
min-width: 26rem;
display:flex;
justify-content: center;
}

.still-life-container img{
    width: 100%;
}


.cafe-art-session{
    min-width: 32rem;
    text-align: center;
   padding: 8rem 4rem;
}