.footer-container{
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
}


.footer-container p{
    color:#4E8FCB;
}

.left-footer-text{
    display: flex;
}


#policy{
    font-weight: 600;
    padding: 0 1rem;
}



.social-media-icons {
    display: flex;
}



.social-media-icons img  {
    padding: 0 0.4rem;
}