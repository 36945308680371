.About-container {
    min-height: calc(100vh - 12rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 10rem;

}




.flore-img{
    width: 26%;
    min-width: 26rem;
    display:flex;
    justify-content: center;
    margin-bottom: 5rem ;
    
}
    
.flore-img img{
    width: 100%;
    border-radius: 0.8rem;
}
    



.About-container p {
    font-size: 1.6rem;
    max-width: 80rem;
    min-width: 30rem;
    color: #353333;
    padding: 2rem 0;
    line-height: 3rem;
    text-align: center;

}

.About-container h1{
    font-size: 4.6vh;
 max-width: 40rem;
 color: #353333;
text-align: center;
}


.about-text {
    width: 100%;
    max-width: 65rem;
    display: flex;
    flex-direction:column;
    align-items:center;
}
